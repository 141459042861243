import { UserOutlined } from '@ant-design/icons';
import './UserBarFw.css';
import { Popover } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import React from 'react';
import { StorageUtil } from '../../util/StorageUtil';
import { WindowUtil } from '../../util/WindowUtil';
import { SecurityUtil } from '../../util/SecurityUtil';

const UserBarFw = () => {
    let config = null;
    //const text=(<div className="user-bar-menu-title">Cuenta</div>);
    //const content = "content"

    

    const createContent = () => {
        const onClick = () => {
            SecurityUtil.setLogout();
        };
        if (config.fields){
            return config.fields.map((field, index) => (
                <div key={index} className="user-bar-field" onClick={onClick} > <span  className={`user-bar-field-icon ${field.icon}`}></span>  {field.label} </div>
            ));
        }
        return "Nada";
    }

    if (StorageUtil.exist('USER_BAR')){
       config = StorageUtil.getItemObject('USER_BAR');
    }
    else
        return null;

    return (
        <div>
            <Popover className="user-bar" placement="bottomRight" title={config.title} content={createContent(config)} trigger="click">
                <Avatar size="large" icon={<UserOutlined />} />
            </Popover>
            
        </div>
    );
};
export default UserBarFw;