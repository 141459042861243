import React from 'react';
import './LoginPage.css';
import { Form, Input, Button, Checkbox, Row, Col, Card } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import { useDispatch } from 'react-redux';
//import { startLoginUsernamePassword } from '../actions/auth';
import { RequestUtil } from '../../util/RequestUtil';
import { EnvConstants } from '../../EnvConstants';
import {useHistory} from 'react-router-dom';
import { SecurityUtil } from '../../util/SecurityUtil';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const LoginPage = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const onFinish = (values) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleLogin = async(e) => {
    let values = form.getFieldsValue();
    //dispatch( startLoginUsernamePassword( values.username, values.password ) );
    try {
      /*
      let resp = await RequestUtil.postData(EnvConstants.GET_URL_AUTH() + "/login",values);
      if (resp){
          SecurityUtil.setLogin(resp);
      }*/
      SecurityUtil.login(values.username, values.password);
    } catch (error) {
      //NotificationUtil.error('Usuario o Password erróneo');
      //alert('Usuario o Password erróneo');
    }
}

  return (

    <Layout className="login-page-layout">
      <Content>
        <Row className="login-page-row-sup" justify="space-around" align="middle"></Row>
        <Row justify="space-around" align="middle">
          <Col xs={{ span: 6 }} lg={{ span: 8 }} xl={{ span: 9 }}></Col>
          <Col xs={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>


          <Card title="Login" style={{ width: 500 }} className="login-card">
              <Form
                form={form}
                {...layout}
                name="basic"
                initialValues={{
                  remember: true,
                  username:"", 
                  password:"" 
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item 
                  label="Username" 
                  name="username" 
                  rules={[{required: true, message: 'Ingrese su usuario!' }]} 
                >
                  <Input />
                </Form.Item>
                <Form.Item 
                    label="Password" 
                    name="password" 
                    rules={[{required: true, message: 'Ingrese su password' } ]} 
                  >
                  <Input.Password />
                </Form.Item>
                <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                  <Checkbox>Recordarme</Checkbox>
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button type="primary" onClick={handleLogin}>
                    Iniciar
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
          <Col xs={{ span: 6 }} lg={{ span: 8 }} xl={{ span: 9 }}></Col>
        </Row>

      </Content>
    </Layout>




  );
};

export default LoginPage;
