import React, { lazy, Suspense } from 'react'
import {
    Switch,
    Route,
    useRouteMatch
  } from "react-router-dom"
import { LoadingPage } from '../framework/loading/LoadingPage';


const TareoRoutes = lazy(() => import('./tareo/TareoRoutes'));
const TarifarioRoutes = lazy(() => import('./tarifario/TarifarioRoutes'));
const MantainerRoutes = lazy(() => import('./mantainers/MantainerRoutes'));
const GestionInstrumentoRoutes = lazy(() => import('./gestionInstrumentos/GestionInstrumentosRoutes'));
const AtcRoutes = lazy(() => import('./atc/AtcRoutes'));
const GaleriaRoutes = lazy(() => import('./galeria/GaleriaRoutes'));
const TarifarioEquipoRoutes = lazy(() => import('./tarifario-equipo/TarifarioEquipoRoutes'));
const statusGeneralInstruccionRoutes = lazy(() => import('./statusGeneralInstruccion/statusGeneralInstruccionRoutes'));
const BandejaSTRoutes = lazy(() => import('./bandejaST/BandejaSTRoutes'));
const BandejaMaterialesRoutes = lazy(() => import('./bandejaMateriales/BandejaMaterialesRoutes'));
const CartasSubContratasRoutes = lazy(() => import('./cartas-sub-contratas/CartasSubContratasRoutes'));
const CadenaMatricesRoutes =lazy(()=> import('./asociacion-cadenas-matrices/CadenaMatricesRoutes') )

export const ModulesRoutes = () => {
    const { path } = useRouteMatch();
    return (
         <Suspense fallback={<LoadingPage/>}>
            <Switch>
                <Route path={`${path}tareo`} component={TareoRoutes} />  
                <Route path={`${path}tarifario`} component={TarifarioRoutes} /> 
                <Route path={`${path}mantainers`} component={MantainerRoutes} /> 
                <Route path={`${path}gestionInstrumento`} component={GestionInstrumentoRoutes} /> 
                <Route path={`${path}atc`} component={AtcRoutes}/>
                <Route path={`${path}galeria`} component={GaleriaRoutes}/>
                <Route path={`${path}tarifario-equipo`} component={TarifarioEquipoRoutes}/>
                <Route path={`${path}status-instruccion`} component={statusGeneralInstruccionRoutes}/>
                <Route path={`${path}bandeja-st`} component={BandejaSTRoutes}/>
                <Route path={`${path}bandeja-materiales`} component={BandejaMaterialesRoutes}/>
                <Route path={`${path}cartas-sub-contratas`} component={CartasSubContratasRoutes}/>

                
                <Route path={`${path}cadenas-matrices`} component={CadenaMatricesRoutes} />
            </Switch>
         </Suspense>
    )
}

//https://learnwithparam.com/blog/how-to-handle-query-params-in-react-router/