import { notification } from 'antd';

export class NotificationUtil {
    static error = (msg) => {
        notification['error']({
            message: 'Error',
            description: msg,
        });
    };

    static success = (msg) => {
        notification['success']({
            message: 'Exito',
            description: msg,
        });
    };
}


