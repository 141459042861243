import { FolderOpenOutlined, FolderOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import {NavLink } from 'react-router-dom';
import { StorageUtil } from '../../../util/StorageUtil';
const { SubMenu } = Menu;

const MenuLayout = () => {
	const [state, setState] = React.useState({});
	let config;
	const createItem = (config, key)=> (// icon={config.icon}
		<Menu.Item key={key} icon={<i className={config.icon}></i>} >
			<NavLink exact to={config.url} >
				{config.label}
			</NavLink>
		</Menu.Item>
	);

	const createFolderItem = (config, keyParent)=> {

		let childrenJsx = config.fields.map((childConfig, index) => {
			const { fields } = childConfig;
			let key = `${keyParent}_${index}`;
			if (fields && fields.length > 0){

				const handleClick = (e) => {
					console.log(e);
					setState({
						...state,
						[key]: !state[key]
					});
				}

				return (
					<SubMenu 
						key={key} 
						icon={state[key] ? <FolderOpenOutlined /> : <FolderOutlined /> }  
						title={childConfig.label}
						onTitleClick={handleClick}
					>
						{createFolderItem(childConfig, key)}
					</SubMenu>
				);
			}
			else{
				return createItem(childConfig, key);
			}
		});

		return 	childrenJsx;

	};


	if (StorageUtil.exist('USER_MENU')){
		config = StorageUtil.getItemObject('USER_MENU');
	}
	else
		return null;


	return (
		<Menu theme="dark" mode="vertical" >
		{createFolderItem(config, 'root')}
		</Menu>
	);
};

export default MenuLayout;