export const menu =
    [
        {
            'icon':'icon-user',
            'label':'Galeria S3',
            'url':'/galeria/galeria-admin'
        },
        {
            'icon':'icon-user',
            'label':'Tareo',
            'url':'/tareo/tareo-admin'
        },
        {
            'icon':'icon-user',
            'label':'Usuario',
            'url':'/Usuario/Usuario-admin'
        },
        // {
        //     'icon':'icon-user',
        //     'label':'Tarifario',
        //     'url':'/tarifario/Tarifario-admin'
        // },
        {
            'icon':'icon-settings',
            'label':'Tarifario Equipo',
            'url':'/tarifario-equipo/tarifario-equipo-admin'
        },
        {
            'label':'Mantenedores',
            'url':'',
            fields: [
                {
                    'icon':'icon-user',
                    'label':'Estación',
                    'url':'/mantainers/estacion/ClienteEstacion-admin'
                },
                {
                    'icon':'icon-user',
                    'label':'Gasto Operativo',
                    'url':'/mantainers/gastos-operativos/Gastos-operativos-admin'
                },
                {
                    'icon':'icon-user',
                    'label':'Vehículo',
                    'url':'/mantainers/vehiculos/Vehiculos-admin'
                },
                {
                    'icon':'icon-user',
                    'label':'Coolers',
                    'url':'/mantainers/coolers/coolers-admin'
                }, 
                {
                    'icon':'icon-user',
                    'label':'Mantenimiento',
                    'url':'/mantainers/mantenimiento/Mantenimiento-admin'
                },
                {
                    'icon':'icon-user',
                    'label':'Proveedores de Insumo',
                    'url':'/mantainers/proveedores-insumos/proveedores-insumos-admin'
                },
                {
                    'icon':'icon-user',
                    'label':'Gestión de Materiales',
                    'url':'/mantainers/materiales/material-admin'
                },
                {
                    'icon':'icon-user',
                    'label':'Configurar Firmante RRHH',
                    'url':'/mantainers/configurar-firmantes-rrhh/Configurar-admin'
                },
                {
                    'icon':'icon-user',
                    'label':'Cuentas de Abono',
                    'url':'/mantainers/cuentas-abono/cuentas-abono-admin'
                },
            ]
        },
        {
            'label':'Gestión de Instrumentos',
            'url':'',
            fields: [
                {
                    'icon':'icon-user',
                    'label':'Instrumentos',
                    'url':'/gestionInstrumento/instrumento/Instrumento-admin'
                },
                {
                    'icon':'icon-user',
                    'label':'Bandeja de Reportes',
                    'url':'/gestionInstrumento/reporte/Reporte-admin'
                },
            ]
        },
        {
            'label':'ATC',
            'url':'',
            fields: [
                {
                    'icon':'icon-user',
                    'label':'Aprobación',
                    fields: [
                        {
                            'icon':'icon-user',
                            'label':'Aprobar Cotización',
                            'url':'/atc/aprobacion/cotizacion-admin'
                        },
                        {
                            'icon':'icon-user',
                            'label':'Aprobar Solicitud de Servicio',
                            'url':'/atc/aprobacion/solicitud-servicio-admin'
                        },
                    ]
                },
                {
                    'icon':'icon-user',
                    'label':'Disponibilidad',
                    fields: [
                        {
                            'icon':'icon-user',
                            'label':'Personal',
                            'url':'/atc/disponibilidad/person-admin'
                        },
                        {
                            'icon':'icon-user',
                            'label':'Instrumentos',
                            'url':'/atc/disponibilidad/instrumentos-admin'
                        },
                        {
                            'icon':'icon-user',
                            'label':'Camionetas',
                            'url':'/atc/disponibilidad/camionetas-admin'
                        },
                    ]
                },
                {
                    'icon':'icon-user',
                    'label':'Cotización',
                    'url':'/atc/cotizacion/cotizacion-admin'
                },
                {
                    'icon':'icon-user',
                    'label':'Solicitud de Servicio',
                    'url':'/atc/solicitud-servicio/solicitud-servicio-admin'
                },
                {
                    'icon':'icon-settings',
                    'label':'Servicios OMA',
                    'url':'/atc/servicios-oma/servicios-oma-admin'
                },
                {
                    'icon':'icon-user',
                    'label':'Sub Contrata Laboratorio',
                    'url':'/atc/sub-contrata-laboratorio/sub-contrata-laboratorio-admin'
                },
                {
                    'icon':'icon-user',
                    'label':'Sub Contrata Instrumentos',
                    'url':'/atc/sub-contrata-instrumentos/sub-contrata-instrumentos-admin'
                },
                {
                    'icon':'icon-user',
                    'label':'Formato de Haberes',
                    'url':'/atc/haberes/haberes-admin'
                },
                {
                    'icon':'icon-user',
                    'label':'Foma 500',
                    'url':'/atc/foma-500/foma-500-admin'
                },
                {
                    'icon':'icon-user',
                    'label':'Viaticos',
                    'url':'/atc/viaticos/viaticos-admin'
                },
                {
                    'icon':'icon-user',
                    'label':'Edición de Muestras',
                    'url':'/atc/ss-edicion-muestras/ss-edicion-muestras-admin'
                },
                {
                    'icon':'icon-user',
                    'label':'Seguimiento Informe de Monitoreo',
                    'url':'/atc/seguimiento-informe-monitoreo/seguimiento-informe-monitoreo-admin'
                },
            ]
        },
        {
            'icon':'icon-user',
            'label':'Status general de Instrucción',
            'url':'/status-instruccion/status-admin'
        },
        {
            'icon':'icon-user',
            'label':'Bandeja ST',
            'url':'/bandeja-st/bandeja-st-admin'
        },
        {
            'icon':'icon-user',
            'label':'Salida Materiales',
            'url':'/bandeja-materiales/bandeja-materiales-admin'
        },
        {
            'icon':'icon-user',
            'label':'Cartas Sub Contratas',
            'url':'/cartas-sub-contratas/cartas-sub-contratas-admin'
        },
        {

            'icon':'icon-user',
            'label':'Cadenas - Matrices',
            'url':'/cadenas-matrices/cadena-matrices-page'

        },
    ]
