import { NotificationUtil } from "./NotificationUtil";
import { SecurityUtil } from "./SecurityUtil";

export class RequestUtil {

    static postData = async(url, reqParams) => {
        const bearer = 'Bearer ' + SecurityUtil.getAccessToken();
        const fetchResult = await fetch(url,{ 
            method: "POST", 
            body: JSON.stringify(reqParams), 
            headers: { 
                "Authorization": bearer,
                "Content-type": "application/json; charset=UTF-8"
            } 
        })
        .catch(err=>{
            if (err instanceof TypeError)
            {
                if (err == "TypeError: Failed to fetch"){
                    NotificationUtil.error(`Error => ${err}`);
                }
                else if (err.name){
                    NotificationUtil.error(`${err.name} => File: ${err?.fileName}, Message: ${err?.message}`);
                }
                else{
                    NotificationUtil.error(`Error => ${err}`);
                }
            }
            else
                NotificationUtil.error(err);
            console.error(err);
            throw new Error(err);
        });

        if (fetchResult.status == 401 && !SecurityUtil.isFull()){
            console.log('redirigiendo a login por token expirado');
            SecurityUtil.setLogout();
        }
        else if (fetchResult.status >= 300){
            //console.log(fetchResult);
            let error = await fetchResult.text();
            console.error(`error status: ${fetchResult.status} => ${error}`);
            throw new Error("message: " + result.message + ", sql: " + result.sql);
        }
        const result = await fetchResult.json(); // parsing the response
        
        if (fetchResult.ok) {
            return result; // return success object
        }
        if (result && result.message)
            NotificationUtil.error(result.message);
        console.error(result);
        throw new Error("message: " + result.message + ", sql: " + result.sql);
        /*
        const responseError = {
            type: 'Error',
            message: result.message || 'Something went wrong',
            data: result.data || '',
            code: result.code || '',
        };
    
        const error = new Error();
        error.info = responseError;
        openNotificationError(responseError.message);
        return (error);*/
 
        /*
        if (response){
            // identificamos todos los errores retornados por el servidor 
            if (response.ok){
                return response.json();
            }
            else{
                const respError = await response.json();
                const errMsg =  respError?.message || respError?.error || (respError?.errors?.length > 0 ? respError.errors[0] : 'HTTP no OK');
                openNotificationError(errMsg);
                throw new Error(errMsg);
            }
        }
        return null;*/



        
    }
    static postDataUrlEncoded = async(url, reqParams) => {
        let formBody = [];
        for (let property in reqParams) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(reqParams[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }

        const fetchResult = await fetch(url,{ 
            method: "POST", 
            body: formBody.join("&"), 
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            } 
        })
        .catch(err=>{
            console.error(err);
            throw new Error(err);
        });
        console.log(fetchResult);
    }
    static getData = async(url, reqParams) => {
        const fetchResult = await fetch(url + '?' + new URLSearchParams(reqParams), {
            method: "GET", 
            mode: 'cors'
        })
        .catch(err=>{
            console.error(err);
            //throw new Error(err);
        });
        console.log(fetchResult);
    }
}