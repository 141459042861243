import './App.css';
import React from 'react'
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { AppRouter } from './routers/AppRouter';
import { TableProvider } from './modules/asociacion-cadenas-matrices/detail/prototype/TableContext';

const App = () => {
  //log.setDefaultLevel(process.env.REACT_APP_LOG_LEVEL);//'debug'
  return (
    <Provider store={ store }>
        <TableProvider>
      <BrowserRouter>
        <AppRouter/>
      </BrowserRouter>
      </TableProvider>
    </Provider>
  )
}

export default App;



