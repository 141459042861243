export class StorageUtil {

    static getItemObject(key){
        const data = window.sessionStorage.getItem(key);
        if (data)
            return JSON.parse(data);
        return null;
    }
    static setItemObject(key, value){
        window.sessionStorage.setItem(key, JSON.stringify(value));
    }
    static removeItemObject(key){
        window.sessionStorage.setItem(key, null);
    }
    static exist(key){
        return StorageUtil.getItemObject(key)!=null;
    }
}

