export class EnvConstants {
    static GET_URL_BASE_BACKEND = () => process.env.REACT_APP_URL_BASE_BACKEND;
    static GET_URL_AUTH = () => process.env.REACT_APP_URL_BASE_BACKEND+ '/api/Auth';
    static GET_URL_READ = () => process.env.REACT_APP_URL_BASE_BACKEND + '/api/ReadUtil/GetData';
    static GET_URL_WRITE = () => process.env.REACT_APP_URL_BASE_BACKEND + '/api/ReadUtil/WriteUtil';
    static GET_REPORT_URL = () => process.env.REACT_APP_REPORT_URL;
    static GET_REPORT_AUTH = () => process.env.REACT_APP_REPORT_AUTH;
    static GET_REPORT_FOLDER = () => process.env.REACT_APP_REPORT_FOLDER;
    static GET_REPORT_FULL_FOLDER = () => process.env.REACT_APP_REPORT_URL + '/reports/reports/' + process.env.REACT_APP_REPORT_FOLDER;

    static GET_FW_FORM_URL_LOAD_BASE = () => process.env.REACT_APP_URL_BASE_BACKEND + '/api/ReadUtil/GetData';
    static GET_FW_FORM_URL_SAVE_BASE = () => process.env.REACT_APP_URL_BASE_BACKEND + '/api/WriteUtil/WriteData';
    static GET_FW_FORM_URL_DELETE_BASE = () => process.env.REACT_APP_URL_BASE_BACKEND + '/api/WriteUtil/WriteData';
    static GET_FW_FORM_URL_UPDATE_BASE = () => process.env.REACT_APP_URL_BASE_BACKEND + '/api/WriteUtil/WriteData';
    static GET_FW_FORM_URL_LIST_BASE = () => process.env.REACT_APP_URL_BASE_BACKEND + '/api/ReadUtil/GetData';
    static GET_FW_FORM_URL_SELECT_BASE = () => process.env.REACT_APP_URL_BASE_BACKEND + '/api/ReadUtil/GetData';
    static GET_FW_FORM_URL_SEARCH_BASE = () => process.env.REACT_APP_URL_BASE_BACKEND + '/api/ReadUtil/GetData';

    static GET_FW_GRID_URL_LOAD_BASE = () => process.env.REACT_APP_URL_BASE_BACKEND + '/api/ReadUtil/GetData';
    static GET_FW_GRID_URL_SAVE_BASE = () => process.env.REACT_APP_URL_BASE_BACKEND + '/api/WriteUtil/WriteData';
    static GET_FW_GRID_URL_UPDATE_BASE = () => process.env.REACT_APP_URL_BASE_BACKEND + '/api/WriteUtil/WriteData';
    static GET_FW_GRID_COLUMN_URL_PROCESS_BASE = () => process.env.REACT_APP_URL_BASE_BACKEND + '/api/WriteUtil/WriteData';

    static GET_FW_URL_LOAD_SQL_BASE = () => process.env.REACT_APP_URL_BASE_BACKEND + '/api/Util/GetDataBySql';
    static GET_FW_URL_UPLOAD_PRESIGNED_URL_PUT = () => process.env.REACT_APP_URL_BASE_BACKEND + '/api/S3/GeneratePreSignedUrlPut';
    static GET_FW_URL_UPLOAD_PRESIGNED_URL_GET = () => process.env.REACT_APP_URL_BASE_BACKEND + '/api/S3/GeneratePreSignedUrlGet';
    static GET_FW_URL_UPLOAD_PRESIGNED_URL_DELETE = () => process.env.REACT_APP_URL_BASE_BACKEND + '/api/S3/GeneratePreSignedUrlDelete';

    static GET_REPORT2_URL = () => process.env.REACT_APP_REPORT2_URL;
}
