import './PrincipalLayout.css';
import React, { useState } from 'react'
import ContainerLayout from './ContainerLayout';
import { Layout} from 'antd';
import HeaderLayout from './header-layout/HeaderLayout';
import MenuLayout from './menu-layout/MenuLayout';
const {  Footer, Sider } = Layout;


const PrincipalLayout = () => {

  const [state, setState] = useState({
      collapsed: false
  });

  const headerConfig = {
    state,
    setState,
  }

  return (
    <>
      <Layout>
        <Sider
          trigger={null} 
          collapsible 
          collapsed={state.collapsed}
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
          }}
        >
          <div className="logo" />
          <MenuLayout />
        </Sider>
        <Layout style={state.collapsed ? { marginLeft: 80 } : { marginLeft: 200 } }>
          <HeaderLayout  
            config = {headerConfig}
          />
          <ContainerLayout/>
          <Footer style={{ textAlign: 'center' }}>
            Framework 1.0 <small> mode: <b>{process.env.NODE_ENV}</b></small>
          </Footer>
        </Layout>
      </Layout>
    </>
  )
}

export default PrincipalLayout;



