import React from 'react';
import { useDispatch } from 'react-redux';

import { menu } from '../data/menu';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { login } from '../auth_module/actions/auth';
import { AuthModuleRoutes } from '../auth_module/AuthModuleRoutes';
import ContainerLayout from '../framework/layout/ContainerLayout';
import PrincipalLayout from '../framework/layout/PrincipalLayout';
import { SecurityUtil } from '../util/SecurityUtil';
import { StorageUtil } from '../util/StorageUtil';
import { UrlUtil } from '../util/UrlUtil';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { LoadingPage } from '../framework/loading/LoadingPage';


export const AppRouter = ({ match, location }) => {    
    const dispatch = useDispatch();
    const [ isLoad, setIsLoad ] = React.useState(false);
    const [ isFull, setIsFull ] = React.useState(false);
    const [ isLoggedIn, setIsLoggedIn ] = React.useState(false);

    StorageUtil.setItemObject('USER_BAR', {
        title: 'Cuenta',
        fields: [
            { label: 'Ajustes', icon: 'fas fa-user' },
            { label: 'Logout', icon: 'icon-user', onclick: "" }
        ]
    });


    StorageUtil.setItemObject('USER_MENU', {
        title: 'Cuenta',
        fields: menu
    }); 
    /*
    React.useEffect(() => {
        let auth = StorageUtil.getItemObject('AUTH');
        if (auth){
            dispatch( login( auth.username, auth.displayName ) );
            setIsLoggedIn( true );
        }
        else{
            setIsLoggedIn( false );
        }
    }, [ dispatch, setIsLoggedIn ]);*/

    React.useEffect(() => {
        setIsLoad(true);
        //setIsFull(SecurityUtil.isFull());
        if (SecurityUtil.isAuthenticated()){
            setIsLoggedIn(true);
        }
        else {

            /*
                * Paginas de apoyo
                https://codebeautify.org/base64-to-json-converter
                https://codebeautify.org/json-to-base64-converter

                * Tomar como ejemplo url mas parametro base64: 
                http://localhost:3000/ex/formularios/form4?params=ewoiZnVsbCI6IjEiLAoidXNlcm5hbWUiOiJhZG1pbiIsCiJwYXNzd29yZCI6InBhc3N3b3JkMSIKfQ==

                * json salida
                {
                "full":"1",
                "username":"admin",
                "password":"password1"
                }

            */

            const par = UrlUtil.paramsUrlToObject();
            if (par && par?.params){
                console.log('params', par.params);
                let params = JSON.parse(atob(par.params));
                if (params?.full==1){
                    SecurityUtil.setFull();
                }

                if(params?.username){
                    setIsLoad(false);
                    SecurityUtil.setUrlWithLogin(par.params);
                    SecurityUtil.loginWithoutReload({
                        username: params.username, 
                        password: params.password, 
                        fnOk: ()=> {
                            setIsLoggedIn(true);
                            setIsLoad(true);
                        },
                        fnError: () => {
                            setIsLoad(true);
                        }
                    });
                }
            }
        }
        setIsFull(SecurityUtil.isFull());
    }, []);

    if (!isLoad){
        return (<LoadingPage/>)
    }

    if (isFull){
        return (
            <Switch>
                <Route path="/" component={ContainerLayout} />    
            </Switch>
        )
    }

    
    return (
        <Switch>   
            <PublicRoute path="/auth" component={ AuthModuleRoutes } isAuthenticated={ isLoggedIn } />
            <PrivateRoute isAuthenticated={ isLoggedIn } path="/" component={ PrincipalLayout } />
            <Redirect to="/auth/login" />
        </Switch>
    )
}