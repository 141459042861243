import React, { useState } from 'react';


const TableContext = React.createContext();

export default TableContext;


export class TableProvider extends React.Component {
    state = {
      unusedHeadersRows: [],
    };
  
    setUnusedHeadersRows = (data) => {
      this.setState({ unusedHeadersRows: data });
    console.log('se llamo')

    };
  
    render() {
      return (
        <TableContext.Provider
          value={{
            unusedHeadersRows: this.state.unusedHeadersRows,
            setUnusedHeadersRows: this.setUnusedHeadersRows,
            // ... otros estados y funciones
          }}
        >
          {this.props.children}
        </TableContext.Provider>
      );
    }
  }
  