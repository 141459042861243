import React from 'react';
import { Spin } from 'antd';
import './loadingStyle.css';
import { LoadingOutlined } from '@ant-design/icons';
import giphy from '../../assets/giphy.gif'

export const LoadingPage = (props) => {

    const antIcon = <LoadingOutlined style={{ fontSize: 40, color: "#B0002D" }} spin/>;
    return (
        <div className="loadingPage" id={props.id} style={{display:"none"}}>
            <img src={giphy}  className="img-loader"/>
            
            {/* <Spin tip="Loading..." size="large" indicator={antIcon} /> */}
        </div>
    )
}
